<template>
	<div v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="hero-image">
			<slot name="heroContent" />
			<picture>
				<source
					v-if="headingImage.imagePortraitWebp"
					:srcset="headingImage.imagePortraitWebp"
					type="image/webp"
					media="(max-width: 500px)"
				/>
				<source
					v-if="headingImage.imagePortrait"
					:srcset="headingImage.imagePortrait"
					media="(max-width: 500px)"
				/>
				<source :srcset="headingImage.imageWebp" type="image/webp" />
				<source :srcset="headingImage.image" />
				<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
			</picture>
			<a v-if="$route.name.startsWith('index')" class="scroll-down smooth-scroll" @click="scrollDown()">
				<span>{{ $t('exploreOurHotel') }}</span>
				<img src="~/assets/images/arrow-down-white-long.png" alt="Scroll" />
			</a>
		</div>
	</div>
</template>

<script setup>
const route = useRoute();

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

defineProps({
	headingImage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
.hero-image {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		z-index: 3;
		position: absolute;
		inset: 0;
		background: rgb(0 0 0 / 5%);
	}

	.slider-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
		z-index: 2;
		transition: all 0.3s ease-in-out;
		animation:
			zoom-in 9000ms ease-in-out forwards,
			fade-in 800ms ease-out;
	}
}

.hero-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.subpage .hero-image {
	height: 50vh;
}

.hero-image::before {
	content: '';
	z-index: 2;
	position: absolute;
	display: block;
	inset: 0;
	background: rgba(0 0 0 / 20%);
}

.scroll-down {
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	z-index: 4;
	font-weight: 300;
	cursor: pointer;

	&:hover img {
		transform: scale(1.1);
	}

	span {
		display: block;
		width: 100%;
		color: #fff;
		margin: 0 0 12px;
		font-size: 20px;
	}

	img {
		width: 55px;
		height: 72px;
		transition: all 0.3s ease-in-out;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.08);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
